

























































import Vue from 'vue'
import TelemetryChart from '@/components/graphs/TelemetryChart.vue'
import AlarmTable from '@/components/AlarmTable.vue'

export default Vue.extend({
  name: 'device',
  components: {
    TelemetryChart,
    AlarmTable
  },
  data: () => ({
    toggle_chart_time: 0
  })
})
