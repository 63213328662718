
























import Vue from 'vue'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { auth, firestore } from '../main'

export default Vue.extend({
  name: 'alarm-table',
  components: {
  },
  props: {
    deviceID: String
  },
  data: () => ({
    alarms: [],
    loaded: false
  }),
  created () {
    this.getAlarms(this)
  },
  methods: {
    getAlarms: async (context: any) => {
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }

      const alarmsref = collection(firestore, 'users', user, 'devices', context.deviceID, 'alarm_timeline')
      const q = query(alarmsref, orderBy('timestamp', 'desc'), limit(10))

      const alarmQuerySnapshot = await getDocs(q)

      console.log(alarmQuerySnapshot.size)

      alarmQuerySnapshot.forEach(async (alarm) => {
        var message
        if (alarm.get('type') === 1) {
          message = (alarm.get('direction') === 0) ? 'Breached temperature threshold' : 'Returned to target temperature'
        } else if (alarm.get('type') === 0) {
          message = (alarm.get('direction') === 0) ? 'Device lost connection' : 'Device reconnected'
        }
        context.alarms.push({
          timestamp: new Date(alarm.get('timestamp') * 1000).toLocaleTimeString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }),
          message: message
        })
      })

      context.loaded = true
    }
  }

})
