























import Vue from 'vue'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { auth, firestore } from '../../main'
import moment from 'moment'

export default Vue.extend({
  name: 'telemetry-chart',
  props: {
    deviceID: String,
    timeType: Number
  },
  data: () => ({
    componentKey: 0,
    loaded: false,
    absolute: true,
    overlay: true,
    chartOptions: {
      noData: {
        text: 'no data available for the given timeframe',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
      title: {
        text: 'Device Temperature',
        align: 'left'
      },
      stroke: {
        curve: 'smooth'
      },
      chart: {
        animations: {
          enabled: false
        },
        id: 'vuechart-example',
        zoom: {
          autoScaleYaxis: true
        },
        // type: 'area',
        height: 350
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        },
        title: {
          text: 'Time'
        }
      },
      yaxis: {
        title: {
          text: 'Temperature (Celsius)'
        }
      },
      tooltip: {
        enabled: true,
        x: {
          format: 'MMM dd h:m TT'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      }
    },
    series: [{
      name: 'Vue Chart',
      data: []
    }]
  }),
  created () {
    this.getDeviceTelemtry(this)
  },
  methods: {
    getDeviceTelemtry: async (context: any) => {
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }
      context.loaded = false

      const telemetryref = collection(firestore, 'users', user, 'devices', context.deviceID, 'telemetry_timeline')

      var test = 0
      var chartdata: any = []
      if (context.timeType === 0) {
        test = moment().subtract(30, 'm').unix()
        const telemetryref = collection(firestore, 'users', user, 'devices', context.deviceID, 'telemetry_timeline')
        const q = query(telemetryref, orderBy('timestamp', 'desc'), where('timestamp', '>', test))

        const telemetryQuerySnapshot = await getDocs(q)
        telemetryQuerySnapshot.docs.reverse().forEach(async (telemetry) => {
          chartdata.push([telemetry.get('timestamp') * 1000, telemetry.get('temperature')])
        })
        // context.chartOptions.xaxis.categories = timestamps
        context.series[0].data = chartdata
        // console.log(chartdata)
      } else if (context.timeType === 1) {
        test = moment().subtract(60, 'm').unix()
        const telemetryref = collection(firestore, 'users', user, 'devices', context.deviceID, 'telemetry_timeline')
        const q = query(telemetryref, orderBy('timestamp', 'desc'), where('timestamp', '>', test))

        const telemetryQuerySnapshot = await getDocs(q)
        telemetryQuerySnapshot.docs.reverse().forEach(async (telemetry) => {
          chartdata.push([telemetry.get('timestamp') * 1000, telemetry.get('temperature')])
        })
        // context.chartOptions.xaxis.categories = timestamps
        context.series[0].data = chartdata
      } else if (context.timeType === 2) {
        test = moment().subtract(6, 'h').unix()
        const telemetryref = collection(firestore, 'users', user, 'devices', context.deviceID, 'telemetry_timeline')
        const q = query(telemetryref, orderBy('timestamp', 'desc'), where('timestamp', '>', test))

        const telemetryQuerySnapshot = await getDocs(q)
        telemetryQuerySnapshot.docs.reverse().forEach(async (telemetry) => {
          chartdata.push([telemetry.get('timestamp') * 1000, telemetry.get('temperature')])
        })
        context.series[0].data = chartdata
      } else {
        test = moment().subtract(1, 'd').unix()
        const telemetryref = collection(firestore, 'users', user, 'devices', context.deviceID, 'telemetry_timeline')
        const q = query(telemetryref, orderBy('timestamp', 'desc'), where('timestamp', '>', test))

        const telemetryQuerySnapshot = await getDocs(q)
        telemetryQuerySnapshot.docs.reverse().forEach(async (telemetry) => {
          chartdata.push([telemetry.get('timestamp') * 1000, telemetry.get('temperature')])
        })
        context.series[0].data = chartdata
      }
      context.componentKey++
      context.loaded = true
    }
  },
  watch: {
    timeType: function (newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.getDeviceTelemtry(this)
    }
  }
})
